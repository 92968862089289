.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Sofia curvy font */
.Sofia_cursive {
  font-family: "Sofia", cursive;
  display: block;
  padding: 0rem 1rem;
  color: white;
  text-decoration: none;
  margin: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}

.Sofia_cursive:focus,
.Sofia_cursive:hover {
  color: white;
}

/* Oswald bold font */
.Oswald_less_bold {
  font-family: "Oswald", sans-serif;
  font-weight: 500;
}

.Oswald_bold {
  font-family: "Oswald", sans-serif;
  font-weight: 700;
}

/* Oswald regular font */
.Oswald_regular {
  font-family: "Oswald", sans-serif;
  font-weight: bolder;
}

/* Oswald light font */
.Oswald_light {
  font-family: "Oswald", sans-serif;
  font-weight: normal;
}

/* Neutral background colors */
.bg-neutral-light {
  --bs-bg-opacity: 1;
  background-color: #f2f1ef;
}

.bg-neutral-body {
  --bs-bg-opacity: 1;
  background-color: #697184;
}

.bg-neutral-dark {
  --bs-bg-opacity: 1;
  background-color: #413f3d;
}

.bg-neutral-secondary {
  --bs-bg-opacity: 1;
  background-color: #d8cfd0;
}

.bg-neutral-primary {
  --bs-bg-opacity: 1;
  background-color: #b1a6a4;
}

/* Add spotify green + red colors! */
/* Add .text-neutral colors as well */

/* Additional button styling */
.btn-align {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Special styling for btn-play (uniquely shaped) */
.btn-play:hover {
  opacity: 0.5;
}
.btn-play:active {
  opacity: 1;
}

/* Icon resizing via "em" */
.bi-lg {
  font-size: 2em;
}

/* Style for button group on listening history TopCard */
.button-group:hover {
  color: white;
  transition: 0.9s;
}

/* Additional styling for concert search card artist name */
.artistName {
  text-shadow: 2px 2px rgb(116, 110, 110);
}

.concertcardbtn {
  float: right;
}

.triangleBtnSection {
  background-color: #b1a6a4 !important;
  transition: 0.2s;
  border: none;
}
/* Additional styling for dropdown menus */
.dropdown-menu > li > a:hover {
  background-color: #697184;
}

.dropdown > button:hover {
  background-color: #697184;
}

/* Color for toggle button on find friends page */
.btn-friendAdded{
  color: #268BD2;
}

.btn-friendNotAdded{
  color: #3FDE77;
}

.btn-friendNotAdded:hover, .btn-friendAdded:hover {
  opacity: 50%;
}

.btn-friendNotAdded:active, .btn-friendAdded:active {
  color:white
}

/* Additional styling for scrollbar feature on main concerts page */
.vertical-scroll {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 75vh;
}

/* Additional styling for words in EventInformation */
.eventDescription {
  text-decoration: none;
  color: black;
}

.eventDescriptionLink {
  text-decoration: none;
  color: black;
}

.findFriendsLink {
  text-decoration: none;
  color: black;
}

a:not(nav-link) hover {
  background-color: #d8cfd0;
  color: black;
}

.userProfileLink {
  text-decoration: none;
  color: black;
}
